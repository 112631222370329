import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AosService } from './services/aos-service/aos.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'domotrend-angular-webpage';


  constructor(private router: Router,
              private aosService: AosService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.aosService.initAos();

    this.translateService.setDefaultLang('hu');
    this.translateService.use('hu');
  }


  getUrl(): string {
    return this.router.url;
  }
}
