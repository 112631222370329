import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '../../../../services/translation-service/translation.service';
import { VenoBoxService } from '../../../../services/venobox-service/veno-box.service';

@Component({
  selector: 'app-portfolio-details',
  templateUrl: './portfolio-details.component.html',
  styleUrls: ['./portfolio-details.component.scss']
})
export class PortfolioDetailsComponent implements OnInit {
  portfolio = [];
  path: string;
  selected: any;

  constructor(private route: ActivatedRoute,
              private translationService: TranslationService,
              private venoboxService: VenoBoxService) {
  }

  ngOnInit(): void {
    this.portfolio = this.translationService.getPortfolioImages();
    this.path = this.route.snapshot.params.id;

    if (this.path) {
      this.selected = this.portfolio.find(value => value.key === this.path);
    }

    this.venoboxService.initVenobox();
  }
}


