<!-- ======= Header ======= -->
<header id="header"
        class="fixed-top">
  <div class="container d-flex align-items-center justify-content-between">
    <h1 class="logo"
        [ngStyle]="{display: collapsed ? '' : 'none' }">
      <a href="../../../">
        <img src="assets/img/logo/domotrend_logo.png"
             alt="domotrend_logo">
      </a>
    </h1>
    <nav class="navbar navbar-expand-lg">
      <button class="navbar-toggler ml-3"
              type="button"
              (click)="toggleNavbar()">
        <div *ngIf="collapsed; else close">
          <span class="navbar-toggler-icon"></span>
        </div>
        <ng-template #close>
          <i class="fas fa-times"
             style="color: white"></i>
        </ng-template>
      </button>
      <div class="collapse navbar-collapse nav-menu d-lg-block"
           [ngClass]="{ 'show': !collapsed }">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link"
               pageScroll
               routerLink="/"
               (click)="collapsed = true"
               href="#main">{{ 'menu.title.home-page' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               pageScroll
               pageScrollOffset="40"
               routerLink="/"
               (click)="collapsed = true"
               href="#about">{{ 'menu.title.about-us' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               pageScroll
               pageScrollOffset="60"
               routerLink="/"
               (click)="collapsed = true"
               href="#services">{{ 'menu.title.services' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               pageScroll
               pageScrollOffset="30"
               routerLink="/"
               (click)="collapsed = true"
               href="#references">{{ 'menu.title.references.header.title' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               pageScroll
               pageScrollOffset="100"
               routerLink="/"
               (click)="collapsed = true"
               href="#portfolio">{{ 'menu.title.portfolio.header.title' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               pageScroll
               pageScrollOffset="40"
               routerLink="/"
               (click)="collapsed = true"
               href="#brands">{{ 'menu.title.brands' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               pageScroll
               [routerLink]="['/wholesale']"
               (click)="collapsed = true"
               href="#wholesale">{{ 'menu.title.wholesale' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               pageScroll
               routerLink="/"
               (click)="collapsed = true"
               href="#contact">{{ 'menu.title.contact' | translate }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
