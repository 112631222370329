<!-- ======= main Section ======= -->
<section id="main" class="d-flex align-items-center justify-content-center">
  <div class="container" data-aos="fade-up">

    <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
      <div class="col-xl-6 col-lg-8">
        <img src="/assets/img/logo/domotrend_logo.png"
             class="img-fluid"
             alt="domotrend_logo">
        <h2>A lakberendezés szakértője</h2>
      </div>
    </div>

  </div>
</section>
