<!-- ======= Wholesale Section ======= -->
<section id="wholesale" class="wholesale">
  <div class="container content" data-aos="fade-up">

    <div class="section-title">
      <h2>Nagykereskedelem</h2>
      <p>Legyen viszonteladó partnerünk</p>
    </div>

    <div data-aos="fade-left"
         data-aos-delay="100">
      <p>Cégünk nyugat-európai tapéta- és textilgyártók, így a Roberto Cavalli, Gianfranco Ferre, Eijffinger,
        Midbec, Muance, Emiliana Parati, Blumarine, Valentin Yudashkin, Weco, ViaRoma, Eustergerling, Venesto,
        Dekortex, Reynaldo, Coconut, Elastron és Mardom márkák magyarországi képviselete. </p>
      <p>Ammenyiben viszonteladó partnerünk szeretne lenni, úgy vegye fel velünk a kapcsolatot az alábbi
        elérhetőségen.</p>
      <div class="contact-details">
        <p>DOMOTREND</p>
        <p>Tel:
          <a href="tel:+36307994980">+36 30 799 4980</a>
        </p>
        <p>Email:
          <a href="mailto:nagyker@domotrend.hu">nagyker@domotrend.hu</a>
        </p>
        <p class="contact">Bemutatóterem: 2040 Budaörs, Szabadság út 94. (Időpontfoglalás szükséges!)</p>
      </div>
    </div>

    <div class="back-to-previous align-self-end ml-auto">
      <a pageScroll
         routerLink="/"
         href="#contact"
         title="Vissza a főoldalra"
         role="button">
        <i class="ri-arrow-go-back-line"></i>
      </a>
    </div>
  </div>
</section>


<!--<section id="services" class="services">-->
<!--  <div class="container" data-aos="zoom-in">-->
<!--    <owl-carousel [options]="mySlideOptions"-->
<!--                  [carouselClasses]="['owl-theme', 'sliding']">-->
<!--      <div class="item" *ngFor="let techLogo of techLogos">-->
<!--        <div>-->
<!--          <img src="../assets/img/services/{{ techLogo.fileName }}"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </owl-carousel>-->
<!--  </div>-->
<!--</section>-->
