<!-- ======= References Section ======= -->
<section id="references" class="references">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>{{ 'menu.title.references.header.title' | translate }}</h2>
      <p>{{ 'menu.title.references.header.subtitle' | translate }}</p>
    </div>

    <div class="row content"
         data-aos="fade-up"
         data-aos-delay="200">

      <div *ngFor="let reference of references | slice:0:3;"
           class="col-lg-4 col-md-6 references-item">
        <a href="assets/img/references/{{ reference.imagePath }}"
           data-gall="portfolioGallery"
           class="venobox">
          <div class="references-wrap">
            <img src="assets/img/references/{{ reference.imagePath }}"
                 class="img-fluid"
                 alt="">
          </div>
        </a>
      </div>
      <div *ngFor="let reference of references | slice:3;"
           [class.d-none]="!showAll"
           class="col-lg-4 col-md-6 references-item">
        <a href="assets/img/references/{{ reference.imagePath }}"
           data-gall="portfolioGallery"
           class="venobox">
          <div class="references-wrap">
            <img src="assets/img/references/{{ reference.imagePath }}"
                 class="img-fluid"
                 alt="">
          </div>
        </a>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-outline-dark"
              (click)="showMore()"
              pageScroll
              href="#references"
              #showButton>{{ this.more }}
      </button>
    </div>
  </div>
</section><!-- End Portfolio Section -->
