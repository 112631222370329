<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <a pageScroll
           href="#main">
          <img class="center" src="assets/img/logo/domotrend_logo.png"
               alt="domotrend logo"
               class="img-fluid"></a>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong><span>Domotrend</span></strong>. Minden jog fenntartva
    </div>

  </div>
</footer><!-- End Footer -->
