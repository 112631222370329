<!-- ======= Portfolio Section ======= -->
<section id="portfolio" class="portfolio">
  <div class="container" data-aos="fade-up">

    <div>
      <h3>{{ 'menu.title.portfolio.header.title' | translate }}</h3>
    </div>

    <div class="row" data-aos="fade-up" data-aos-delay="200">

      <div class="col-lg-4 col-md-6 portfolio-item"
           *ngFor="let portfolioItem of portfolio">
        <a routerLink="/portfolio-details/{{portfolioItem.key}}"
           title="Részletek">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/{{portfolioItem.key}}/{{portfolioItem.key}}.jpg"
                 class="img-fluid"
                 alt="{{portfolioItem.key}}.jpg">
            <div class="portfolio-info">
              <h4>{{portfolioItem.name}}</h4>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
