import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import {WholesaleComponent} from './components/layout/wholesale/wholesale.component';
import {PortfolioDetailsComponent} from './components/layout/portfolio/portfolio-details/portfolio-details.component';

const routes: Routes = [
  {path: '', component: LayoutComponent},
  {path: 'wholesale', component: WholesaleComponent},
  {path: 'portfolio-details/:id', component: PortfolioDetailsComponent},
  {path: 'not-found', component: PageNotFoundComponent},
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64]
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
