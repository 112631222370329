import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslationService } from '../../../services/translation-service/translation.service';
import { VenoBoxService } from '../../../services/venobox-service/veno-box.service';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {
  @ViewChild('showButton') showButton: ElementRef;

  more = 'További képek...';
  less = 'Kevesebb kép';
  references = [];
  showAll = false;

  constructor(private translationService: TranslationService,
              private venoboxService: VenoBoxService) {
  }

  ngOnInit(): void {
    this.references = this.translationService.getReferenceImages();
    this.venoboxService.initVenobox();
  }

  showMore(): void {
    this.showAll = !this.showAll;

    if (this.showAll) {
      this.showButton.nativeElement.innerText = this.less;
    } else {

      this.showButton.nativeElement.innerText = this.more;
    }
  }
}
