<!-- ======= Brands Section ======= -->
<section id="brands" class="brands">
  <div class="container" data-aos="fade-up">

    <h3>Márkáink</h3>

    <div class="row brands-container" data-aos="fade-up" data-aos-delay="200">

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Arte.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Blumarine.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/BeBlumarine.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Casamance.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Dekortex.gif" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/delius-logo.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/DWC.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/EGLO_Logo_landscape.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Eichholtz.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/eijffinger.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Elastron.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Elter.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Eustergerling.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/GF.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Glamora.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Gözze.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Hanna_Wendelbo.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/IEP.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/jover.svg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/marburg_logo.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Mardom.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Midbec.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Mopa.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Muance.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Odoro.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/pnt.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Pufas.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/rc%20home.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/RebelWalls.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Reynaldo.gif" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/rc-logo-300.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Sandberg.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Sanderson.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Satino.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/Sellaton.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/texdecor.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/TresTintas.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/valentin%20yudashkin.jpg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/ViaRoma60.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-4 col-md-6 brands-item">
        <img src="../../../../assets/img/brands/WECO.jpg" class="img-fluid" alt="">
      </div>

    </div>


  </div>
</section><!-- End Portfolio Section -->


<!--<section id="services" class="services">-->
<!--  <div class="container" data-aos="zoom-in">-->
<!--    <owl-carousel [options]="mySlideOptions"-->
<!--                  [carouselClasses]="['owl-theme', 'sliding']">-->
<!--      <div class="item" *ngFor="let techLogo of techLogos">-->
<!--        <div>-->
<!--          <img src="../assets/img/services/{{ techLogo.fileName }}"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </owl-carousel>-->
<!--  </div>-->
<!--</section>-->
