<!-- ======= Portfolio Details Section ======= -->
<section id="portfolio-details" class="portfolio-details">
  <div id="top" class="container" data-aos="fade-up">

    <div class="section-title content">
      <h2>{{this.selected.subName}}</h2>
    </div>
    <div class="row" data-aos="fade-up" data-aos-delay="200">
      <div *ngFor="let item of this.selected.details"
           class="col-lg-4 col-md-6 portfolio-details-item">
        <a href="assets/img/portfolio/{{selected.key}}/details/{{item.fileName}}"
           data-gall="portfolioGallery"
           class="venobox">
          <div class="portfolio-details-wrap">
            <img src="assets/img/portfolio/{{selected.key}}/details/{{item.fileName}}"
                 class="img-fluid"
                 alt="">
          </div>
        </a>
      </div>
    </div>
    <div class="back-to-previous align-self-end ml-auto">
      <a pageScroll=""
         routerLink="/"
         href="#portfolio"
         title="Vissza a főoldalra"
         role="button">
        <i class="ri-arrow-go-back-line"></i>
      </a>
    </div>
  </div>
</section><!-- End Portfolio Details Section -->
