<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<a pageScroll
   [href]="getUrl().length > 1 ? '#top' : '#main'"
   class="back-to-top"
   role="button">
  <i class="fas fa-arrow-up"></i>
</a>
<div id="preloader"></div>
