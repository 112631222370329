import { Component } from '@angular/core';

@Component({
  selector: 'app-wholesale',
  templateUrl: './wholesale.component.html',
  styleUrls: ['./wholesale.component.scss']
})

export class WholesaleComponent {

  constructor() {
  }


}
