import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class VenoBoxService {

  constructor() {
  }

  initVenobox(): void {
    // Initiate venobox (lightbox feature)
    $(document).ready(function () {
      $('.venobox').venobox({
        'share': false
      });
    });
  }
}
