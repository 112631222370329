<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>Kapcsolat</h2>
      <p>Elérhetőségeink</p>
    </div>

    <div class="contact-wrap">
      <div class="col-lg-8 col-sm-6 embed-responsive-item">
        <iframe style="border:0;
                        width: 100%;
                        height: 360px;"
                src="https://maps.google.com/maps?width=100%25&amp;height=360&amp;hl=en&amp;q=2040%20Budaörs%20Szabads%0D%0A%C3%A1g%20%C3%BAt%2094.+(Domotrend)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                frameborder="0"
                allowfullscreen>
        </iframe>
      </div>
      <div class="col-lg-4 col-sm-6 info">
        <div class="d-flex flex-column">
          <div>
            <div class="contact-div">
              <h4>DOMOTREND KFT.</h4>
              <p>Bemutatóterem: 2040 Budaörs, Szabadság út 94.</p>
              <p>A bemutatóterem <span
                style="color: black"><strong>előre egyeztetett időpontban</strong></span>
                látogatható.</p>
            </div>
            <hr>
            <div class="contact-div">
                <p>
                  Telefon: <a href="tel:+36307994980">+36 30 799 4980</a>
                </p>
              <p>
                Email: <a href="mailto:info@domotrend.hu">info@domotrend.hu</a>
              </p>
              <p>
                Web: <a href="https://domotrend.hu">domotrend.hu</a>
              </p>
            </div>
            <hr>
            <div class="contact-div">
              <h4>Cégadatok:</h4>
              <p>Székhely: 1238 Budapest, Török utca 4/A.</p>
              <p>Adószám: 23024821-2-43</p>
              <p>Cégjegyzékszám: 01-09-949470</p>
            </div>
            <div class="contact-div">
              <div class="social-links mt-3">
                <a href="https://www.facebook.com/DomoTrend" target="_blank"><i class="bx bxl-facebook"></i></a>
                <a href="https://www.instagram.com/Domotrend" target="_blank"><i class="bx bxl-instagram"></i></a>
                <a href="https://www.flickr.com/photos/domotrend/albums" target="_blank"><i class="bx bxl-flickr"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section><!-- End Contact Section -->
