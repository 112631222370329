import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from './components/layout/contact/contact.component';
import { AboutUsComponent } from './components/layout/about-us/about-us.component';
import { ReferencesComponent } from './components/layout/references/references.component';
import { ServicesComponent } from './components/layout/services/services.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PortfolioComponent } from './components/layout/portfolio/portfolio.component';
import { PortfolioDetailsComponent } from './components/layout/portfolio/portfolio-details/portfolio-details.component';
import { MainComponent } from './components/layout/main/main.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrandsComponent } from './components/layout/brands/brands.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WholesaleComponent } from './components/layout/wholesale/wholesale.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    AboutUsComponent,
    ServicesComponent,
    ReferencesComponent,
    PortfolioComponent,
    PortfolioDetailsComponent,
    MainComponent,
    BrandsComponent,
    PageNotFoundComponent,
    LayoutComponent,
    WholesaleComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'hu',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxPageScrollCoreModule,
    NgxPageScrollModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
