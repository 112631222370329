<!-- ======= Services Section ======= -->
<section id="services" class="services">
  <div class="container" data-aos="fade-up">

    <div class="content">
      <h3>Szolgáltatásaink</h3>
    </div>

    <div class="row">
      <div class="col-lg-6 order-lg-1 order-1"
           data-aos="fade-left">
        <img src="assets/img/services.jpg" class="img-fluid" alt="">
      </div>
      <div class="col-lg-6 order-lg-2 order-2 content"
           data-aos="fade-right"
           data-aos-delay="100">
        <p>
          Vállalkozásunk főként textil- és tapéta nagykereskedelemmel foglalkozik. Kereskedelmi
          tevékenységünkön túl, lakberendezési szolgáltatásokat is biztosítunk vevőink és lakberendezőink
          számára, melyek keretein belül végig kísérjük ügyfeleink elképzeléseit a megrendeléstől egészen a
          megvalósulásig. Célunk, hogy olyan komplex szolgáltatást nyújtsunk, mely a megrendelőink igényeit
          teljeskörűen, a legmagasabb színvonalon elégíti ki, ezért a kezdetektől fogva olyan beszállító
          partnerekkel való együttműködésre törekszünk, akik mind termékeik, mind pedig szolgáltatásaik terén
          magas minőséget képviselnek.
        </p>
        <ul>
          <li><i class="ri-check-double-line"></i> Projektkezelés</li>
          <li><i class="ri-check-double-line"></i> Teljeskörű lakberendezési szolgáltatás (csomagok)</li>
          <li><i class="ri-check-double-line"></i> Árajánlat készítés</li>
          <li><i class="ri-check-double-line"></i> Bútortervezés, gyártásszervezés, szállítás, funkcionális
            elhelyezés
          </li>
          <li><i class="ri-check-double-line"></i> Kárpitozás</li>
          <li><i class="ri-check-double-line"></i> Függönyözés megtervezése, varrása, helyszíni installálása
          </li>
          <li><i class="ri-check-double-line"></i> Helyszíni felmérés, helyszíni szerelés</li>
          <li><i class="ri-check-double-line"></i> Tapétázás</li>
        </ul>
      </div>
    </div>

    <div class="collage d-flex justify-content-center">
      <div class="col-8 collage-left">
        <div class="d-flex justify-content-end">
          <img src="assets/img/collage/Alaprajz.jpg"
               title="alaprajz"
               class="img-fluid"
               alt="alaprajz">
        </div>
        <div class="d-flex justify-content-end">
          <img src="assets/img/collage/Fuggonyozes.jpg"
               class="img-fluid"
               alt="fuggonyozes">
          <img src="assets/img/collage/Hangulat.jpg"
               class="img-fluid"
               alt="hangulat">
        </div>
        <div class="d-flex justify-content-end">
          <img src="/assets/img/collage/Varras.jpg"
               class="img-fluid"
               alt="varras">
        </div>
      </div>
      <div class="col-4 collage-right">
        <div class="d-flex justify-content-start">
          <img src="assets/img/collage/Karnisszereles.jpg"
               class="img-fluid"
               alt="karnisszereles">
        </div>
        <div class="d-flex justify-content-start">
          <img src="assets/img/collage/Tervrajz.png"
               class="img-fluid"
               alt="tervrajz">
        </div>
      </div>
    </div>
  </div>
</section><!-- End Services Section -->
