<section id="notFound" >
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>{{ 'pages.notFound.header.title' | translate }}</h2>
      <p>{{ 'pages.notFound.title' | translate }}</p>
    </div>

    <div class="back-to-home">
      <a routerLink="/"
         style="display: inline;">
        <i class="fas fa-arrow-left"></i>
        {{ 'button.backToHome' | translate }}
      </a>
    </div>
  </div>
</section>
