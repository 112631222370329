<!-- ======= About Section ======= -->
<section id="about" class="about">
  <div class="container" data-aos="fade-up">

    <h3>A cég története</h3>

    <div class="row">
      <div class="col-lg-6 order-1 order-lg-2"
           data-aos="fade-left"
           data-aos-delay="100">
        <img src="assets/img/about.jpg" class="img-fluid" alt="">
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
           data-aos="fade-right"
           data-aos-delay="100">
        <p>
          Cégünk, mint családi vállalkozás, 2010-ben alakult Budapest belvárosában. Elsősorban exkluzív tapéták,
          falburkolatok, függöny-, és dekoranyagok, bútorok és egyedi bútorok, karnisok és egyéb kiegészítők – takarók,
          ágyneműk, szőnyegek – forgalmazásával foglalkozunk.
        </p>
        <p>
          Közel 20 nemzetközi márka kizárólagos képviselői vagyunk Magyarországon, Európa vezető tapéta- és
          textilgyártói közül. A teljesség igénye nélkül olasz, holland, svéd, német, lengyel és portugál beszállítók
          termékeit forgalmazzuk.
        </p>
        <p>
          A bútoriparban eltöltött több, mint 20 év tapasztalata inspirált egy olyan lakberendezési üzlet létrehozására,
          ahol személyre szabott szolgáltatást kap minden vásárló, aki egyedi igénye és elképzelése alapján szeretné
          lakóhelyét igazi otthonná alakítani. Mindezt egy finom kávé mellett, családias légkörben.
        </p>
        <p>Tóth Eszter, tulajdonos</p>
      </div>
    </div>

  </div>
</section>
