import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as huTranslation from '../../../assets/i18n/hu.json';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private translateService: TranslateService) {
  }

  getReferenceImages() {
    if (this.translateService.currentLang) {
      return Object.assign([], (huTranslation as any).menu.title.references.content);
    }
  }

  getPortfolioImages() {
    if (this.translateService.currentLang) {
      return Object.assign([], (huTranslation as any).menu.title.portfolio.content);
    }  }
}
