import { Component, OnInit } from '@angular/core';
import { TranslationService } from '../../../services/translation-service/translation.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  portfolio = [];

  constructor(private translationService: TranslationService) {
  }

  ngOnInit(): void {
    this.portfolio = this.translationService.getPortfolioImages();
  }

}
